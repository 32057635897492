import { ApiClient, useNotice, useResource } from "adminjs";
import { useCallback, useState } from "react";
const api = new ApiClient();
export const useReorderPrayees = (queueId, onSuccess) => {
    const prayeeResource = useResource("PrayingQueuePrayee");
    const showNotice = useNotice();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const handleReorder = useCallback(async (newOrder) => {
        try {
            setLoading(true);
            const res = await api.resourceAction({
                actionName: "reorder",
                resourceId: prayeeResource.id,
                method: "post",
                data: { order: newOrder, praying_queue_id: queueId }
            });
            if (res.data.notice) {
                showNotice(res.data.notice);
            }
            onSuccess();
        }
        catch (error) {
            setError(error.message);
            showNotice({ message: error.message, type: "error" });
        }
        finally {
            setLoading(false);
        }
    }, []);
    return { handleReorder, loading, error };
};
