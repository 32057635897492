import React, { useMemo } from 'react';
import { Tabs, Tab } from '@adminjs/design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrayingQueueIntercessors } from './PrayingQueueIntercessors.js';
import { PrayingQueuePrayees } from './PrayingQueuePrayees.js';
const PrayingQueuePeople = ({ record }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedTab = useMemo(() => {
        const search = new URLSearchParams(location.search);
        const tab = search.get('tab') || 'intercessors';
        return tab;
    }, [location.search]);
    const changeTab = (newTab) => {
        const search = new URLSearchParams(location.search);
        search.set('perPage', '999');
        search.set('tab', newTab);
        navigate({ search: search.toString() }, { replace: true });
    };
    return (React.createElement(Tabs, { currentTab: selectedTab, onChange: changeTab },
        React.createElement(Tab, { id: "intercessors", label: "Intercessors" },
            React.createElement(PrayingQueueIntercessors, { queueId: record.params.id, queueRecord: record })),
        React.createElement(Tab, { id: "prayees", label: "Prayees" },
            React.createElement(PrayingQueuePrayees, { queueId: record.params.id, queueRecord: record }))));
};
export default PrayingQueuePeople;
