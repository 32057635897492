import { Box, Loader, Table, TableCaption, Button, Icon, TableHead, TableBody, TableRow, TableCell, } from '@adminjs/design-system';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { usePrayingQueuePrayees } from '../../hooks/usePrayingQueuePrayees.js';
import React, { useState } from 'react';
import { ViewHelpers, useResource, useTranslation } from 'adminjs';
import { PrayingQueuePrayeeItem } from './PrayingQueuePrayeeItem.js';
import { CreateNewPrayingQueuePerson } from './CreateNewPrayingQueuePerson.js';
import { useReorderPrayees } from './useReorderPrayees.js';
const view = new ViewHelpers();
export const PrayingQueuePrayees = ({ queueId, queueRecord }) => {
    const [showNew, setShowNew] = useState(false);
    const prayeeResource = useResource('PrayingQueuePrayee');
    const translation = useTranslation();
    const { prayees, loading, reload } = usePrayingQueuePrayees(queueId);
    const { handleReorder, loading: savingOrder } = useReorderPrayees(queueId, () => reload());
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldOrder = prayees.map((item) => item.id);
            const oldIndex = oldOrder.indexOf(active.id);
            const newIndex = oldOrder.indexOf(over.id);
            const newOrder = arrayMove(oldOrder, oldIndex, newIndex);
            handleReorder(newOrder);
        }
    };
    const translateProperty = (property) => {
        return translation.translateProperty(property, prayeeResource.id);
    };
    if (loading || savingOrder) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: handleDragEnd },
        React.createElement(SortableContext, { items: prayees.map((item) => item.id), strategy: verticalListSortingStrategy },
            React.createElement(Box, { pt: "x4" },
                React.createElement(Table, null,
                    React.createElement(TableCaption, null,
                        React.createElement(Box, { display: "flex", justifyContent: "flex-start", alignItems: "center" },
                            React.createElement(Button, { ml: 5, variant: "text", onClick: () => setShowNew(true) },
                                React.createElement(Icon, { icon: "PlusCircle" }),
                                translation.translateAction('new', prayeeResource.id)))),
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            prayeeResource.listProperties.map((property) => (React.createElement(TableCell, { key: property.name }, translateProperty(property.name)))),
                            React.createElement(TableCell, null))),
                    React.createElement(TableBody, null, prayees.map((row) => (React.createElement(PrayingQueuePrayeeItem, { key: row.params.id, item: row, onDelete: () => reload() }))))),
                React.createElement(CreateNewPrayingQueuePerson, { type: "prayee", isOpen: showNew, queueId: queueId, queueRecord: queueRecord, onClose: () => setShowNew(false), onSuccess: () => {
                        setShowNew(false);
                        reload();
                    } })))));
};
