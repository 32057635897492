import { useResource, DrawerPortal, BasePropertyComponent, useRecord, useTranslation } from 'adminjs';
import React, { useEffect, useMemo } from 'react';
import { Overlay, Box, Button, Icon, DrawerContent, DrawerFooter, Loader } from '@adminjs/design-system';
export const CreateNewPrayingQueuePerson = ({ type, queueId, queueRecord, isOpen, onClose, onSuccess, }) => {
    const intercessorResource = useResource('PrayingQueueIntercessor');
    const prayeeResource = useResource('PrayingQueuePrayee');
    const createResource = type === 'intercessor' ? intercessorResource : prayeeResource;
    const { translateButton } = useTranslation();
    const initialRecord = useMemo(() => {
        return {
            params: {
                ...createResource.editProperties.reduce((acc, property) => {
                    return {
                        ...acc,
                        [property.name]: property.props.defaultValue || undefined,
                    };
                }, {}),
                praying_queue_id: queueId,
            },
            populated: {
                praying_queue_id: queueRecord,
            },
            baseError: null,
            errors: {},
            recordActions: [],
            bulkActions: [],
        };
    }, [type, queueId, queueRecord]);
    const { record, handleChange, submit: handleSubmit, loading, setRecord, } = useRecord(initialRecord, createResource.id);
    useEffect(() => {
        setRecord(initialRecord);
    }, [initialRecord]);
    const submit = (event) => {
        console.log('submit');
        event.preventDefault();
        handleSubmit().then((response) => {
            if (response.data.record.id && !Object.keys(response.data.record.errors).length) {
                handleChange({ ...initialRecord });
                console.log('success');
                onSuccess();
            }
        });
        return false;
    };
    if (!isOpen) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Overlay, { onClick: onClose }),
        React.createElement(DrawerPortal, null,
            React.createElement(Box, { as: "form", onSubmit: submit, flex: true, flexGrow: 1, flexDirection: "column" },
                React.createElement(DrawerContent, null,
                    React.createElement(Button, { type: "button", rounded: true, variant: "outlined", size: "icon", onClick: onClose, mb: 32 },
                        React.createElement(Icon, { icon: "X" })),
                    loading && React.createElement(Loader, null),
                    createResource.editProperties.map((property) => (React.createElement(BasePropertyComponent, { key: property.name, where: "edit", resource: createResource, property: property, record: record, onChange: handleChange })))),
                React.createElement(DrawerFooter, null,
                    React.createElement(Button, { type: "submit", variant: "contained" },
                        React.createElement(Icon, { mr: 5, icon: "Save" }),
                        translateButton('save', createResource.id)))))));
};
