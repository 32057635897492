import { ApiClient } from "adminjs";
import { useCallback, useEffect, useState } from "react";
import dayjs from "../../helpers/dayjs.js";
const api = new ApiClient();
export const usePrayingQueueTurns = (queueId, date) => {
    const [turns, setTurns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const params = {
                "praying_queue_id": queueId,
                sortBy: "id",
                perPage: "999",
            };
            if (date) {
                const dateObj = dayjs(date).utc().startOf("day");
                params["date"] = dateObj.toISOString();
            }
            const res = await api.resourceAction({
                actionName: "list",
                resourceId: "PrayingQueueTurn",
                params: params
            });
            setTurns(res.data.records);
        }
        catch (error) {
            setError(error.message);
        }
        finally {
            setLoading(false);
        }
    }, [queueId, date]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return { turns, loading, error, reload: fetchData };
};
