import React, { useState } from 'react';
import { Box, Button, Icon, Loader, DatePicker, TableCaption, Table, TableHead, TableRow, TableCell, TableBody, Text, } from '@adminjs/design-system';
import dayjs from 'dayjs';
import { usePrayingQueueIntercessors } from '../../hooks/usePrayingQueueIntercessors.js';
import { usePrayingQueuePrayees } from '../../hooks/usePrayingQueuePrayees.js';
import { usePrayingQueueTurns } from './usePrayingQueueTurns.js';
import { BasePropertyComponent, useResource, useTranslation } from 'adminjs';
import { ManagePrayingQueueTurnsForDate } from './ManagePrayingQueueTurnsForDate.js';
const PrayingQueueTurns = ({ record }) => {
    const [showNew, setShowNew] = useState(false);
    const turnResource = useResource('PrayingQueueTurn');
    const prayeeResource = useResource('PrayingQueuePrayee');
    const intercessorResource = useResource('PrayingQueueIntercessor');
    const translation = useTranslation();
    const [date, setDate] = useState(dayjs().toISOString());
    const { intercessors, loading: loadingIntercessors } = usePrayingQueueIntercessors(record.params.id);
    const { prayees, loading: loadingPrayees } = usePrayingQueuePrayees(record.params.id);
    const { turns, loading: loadingTurns, reload: reloadTurns, } = usePrayingQueueTurns(record.params.id, dayjs(date || new Date())
        .utc()
        .startOf('day')
        .toISOString());
    const translateProperty = (property) => {
        return translation.translateProperty(property, turnResource.id);
    };
    const handleDateChange = (newDate) => {
        setDate(newDate || null);
    };
    if (loadingIntercessors || loadingPrayees || loadingTurns) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(Box, { pt: "x4" },
        React.createElement(Table, null,
            React.createElement(TableCaption, null,
                React.createElement(Box, { flex: true, justifyContent: "start", alignItems: "center" },
                    React.createElement(Text, { mr: 5, as: "span" }, translation.translateProperty('date', turnResource.id)),
                    React.createElement(Box, { mr: 5 },
                        React.createElement(DatePicker, { propertyType: "date", onChange: handleDateChange, value: date })),
                    React.createElement(Button, { type: "button", variant: "outlined", onClick: () => setShowNew(true) },
                        React.createElement(Icon, { icon: "Edit" }),
                        translation.translateAction('edit', turnResource.id)))),
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, translateProperty('prayee_id')),
                    React.createElement(TableCell, null, translateProperty('intercessor_id')),
                    React.createElement(TableCell, null, translateProperty('intercessor_notified_at')))),
            React.createElement(TableBody, null, prayees.map((row) => {
                let intercessor = null;
                const turn = turns.find((turn) => turn.params.prayee_id === row.params.person_id);
                if (turn) {
                    intercessor = intercessors.find((i) => i.params.person_id === turn.params.intercessor_id);
                }
                return (React.createElement(TableRow, { key: row.id },
                    React.createElement(TableCell, null,
                        React.createElement(BasePropertyComponent, { resource: prayeeResource, property: prayeeResource.properties.person_id, record: row, where: "list" })),
                    React.createElement(TableCell, null, !!intercessor ? (React.createElement(BasePropertyComponent, { resource: intercessorResource, property: intercessorResource.properties.person_id, record: intercessor, where: "list" })) : null),
                    React.createElement(TableCell, null, turn ? (React.createElement(BasePropertyComponent, { resource: turnResource, property: turnResource.properties.intercessor_notified_at, record: turn, where: "list" })) : null)));
            }))),
        React.createElement(ManagePrayingQueueTurnsForDate, { isOpen: showNew, onClose: () => setShowNew(false), onSuccess: () => {
                console.log('success');
                setShowNew(false);
                reloadTurns();
            }, date: date, intercessors: intercessors, turns: turns, queueId: record.params.id })));
};
export default PrayingQueueTurns;
