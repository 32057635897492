import { ApiClient, useNotice, useResource } from "adminjs";
import { useCallback, useState } from "react";
const api = new ApiClient();
export const useManageTurnPeople = (queueId, onSuccess) => {
    const turnResource = useResource("PrayingQueueTurn");
    const showNotice = useNotice();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const handleSave = useCallback(async (date, people) => {
        try {
            setErrors({});
            setLoading(true);
            const res = await api.resourceAction({
                actionName: "editPeopleForDate",
                resourceId: turnResource.id,
                method: "post",
                data: { praying_queue_id: queueId, date, people }
            });
            if (res.data.notice) {
                showNotice(res.data.notice);
                if (res.data.notice.type !== 'error') {
                    onSuccess();
                }
                else {
                    setErrors(res.data.record.errors);
                }
            }
        }
        catch (error) {
            setErrors({ error: { message: error.message } });
            showNotice({ message: error.message, type: "error" });
        }
        finally {
            setLoading(false);
        }
    }, []);
    return { handleSave, loading, errors };
};
