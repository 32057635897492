import React from 'react';
import { Avatar, ValueGroup } from '@adminjs/design-system';
import { useTranslation } from 'adminjs';
const UserAvatar = ({ record, property, where, ...props }) => {
    const { translateProperty } = useTranslation();
    const { picture, email } = record.params;
    if (where === 'list') {
        return (React.createElement(Avatar, { src: picture, alt: email }, !!email && email.charAt(0)));
    }
    return (React.createElement(ValueGroup, { label: property.hideLabel ? null : translateProperty(property.name, property.resourceId) },
        React.createElement(Avatar, { src: picture, alt: email }, !!email && email.charAt(0))));
};
export default UserAvatar;
