import { ApiClient } from "adminjs";
import { useCallback, useEffect, useState } from "react";
const api = new ApiClient();
export const usePrayingQueuePrayees = (queueId) => {
    const [prayees, setPrayees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchPrayees = useCallback(async () => {
        try {
            setLoading(true);
            const res = await api.resourceAction({
                actionName: "list",
                resourceId: "PrayingQueuePrayee",
                params: { "filters.praying_queue_id": queueId, sortBy: "order", perPage: "999" }
            });
            setPrayees(res.data.records);
        }
        catch (error) {
            setError(error.message);
        }
        finally {
            setLoading(false);
        }
    }, [queueId]);
    useEffect(() => {
        fetchPrayees();
    }, [fetchPrayees]);
    return { prayees, loading, error, reload: fetchPrayees };
};
