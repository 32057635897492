import { Box, Button, DrawerContent, DrawerFooter, Icon, Overlay, Table, TableBody, TableCell, TableHead, TableRow, } from '@adminjs/design-system';
import { BasePropertyComponent, DrawerPortal, useResource, useTranslation } from 'adminjs';
import React, { useEffect, useState } from 'react';
import dayjs from '../../helpers/dayjs.js';
import { useManageTurnPeople } from './useManageTurnPeople.js';
const getFormData = (intercessors, turns) => {
    return intercessors.reduce((acc, intercessor) => {
        const intercessorTurn = turns.find((t) => t.params.intercessor_id === intercessor.params.person_id);
        return {
            ...acc,
            [intercessor.params.person_id]: intercessorTurn ? intercessorTurn.params.prayee_id : null,
        };
    }, {});
};
export const ManagePrayingQueueTurnsForDate = ({ isOpen, onClose, date, intercessors, turns, queueId, onSuccess, }) => {
    const { handleSave, errors: saveErrors } = useManageTurnPeople(queueId, onSuccess);
    const [formData, setFormData] = useState(getFormData(intercessors, turns));
    const turnResource = useResource('PrayingQueueTurn');
    const translate = useTranslation();
    useEffect(() => {
        setFormData(getFormData(intercessors, turns));
    }, [intercessors, turns]);
    if (!isOpen) {
        return null;
    }
    const translateProperty = (property) => {
        return translate.translateProperty(property, turnResource.id);
    };
    const handleSubmit = (event) => {
        console.log('submit');
        event.preventDefault();
        handleSave(date, formData);
        return false;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Overlay, { onClick: onClose }),
        React.createElement(DrawerPortal, null,
            React.createElement(Box, { as: "form", onSubmit: handleSubmit, flex: true, flexGrow: 1, flexDirection: "column" },
                React.createElement(DrawerContent, null,
                    React.createElement(Button, { type: "button", rounded: true, variant: "outlined", size: "icon", onClick: onClose, mb: 32 },
                        React.createElement(Icon, { icon: "X" })),
                    React.createElement(Box, { mb: 8 },
                        translateProperty('date'),
                        ": ",
                        dayjs(date).format('DD.MM.YYYY')),
                    React.createElement(Table, null,
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, { style: { display: 'flex', justifyContent: 'space-evenly' } },
                                React.createElement(TableCell, { width: "100%" }, translateProperty('prayee_id')),
                                React.createElement(TableCell, { width: "100%" }, translateProperty('intercessor_id')))),
                        React.createElement(TableBody, null, intercessors.map((row) => {
                            const intercessorPersonId = row.params.person_id;
                            const prayeeErrors = saveErrors[`${intercessorPersonId}.prayee`];
                            const turn = turns.find((t) => t.params.intercessor_id === intercessorPersonId);
                            return (React.createElement(TableRow, { key: row.id, style: { display: 'flex', justifyContent: 'space-evenly' } },
                                React.createElement(TableCell, { width: "100%" },
                                    React.createElement(BasePropertyComponent, { where: "edit", resource: turnResource, property: turnResource.properties.prayee_id, onChange: (_property, uuid) => {
                                            setFormData((oldData) => ({
                                                ...oldData,
                                                [row.params.person_id]: uuid,
                                            }));
                                        }, record: {
                                            params: {
                                                prayee_id: formData[intercessorPersonId] || null,
                                            },
                                            populated: {},
                                            bulkActions: [],
                                            recordActions: [],
                                            baseError: null,
                                            errors: { prayee_id: prayeeErrors },
                                        } })),
                                React.createElement(TableCell, { width: "100%" },
                                    React.createElement(BasePropertyComponent, { where: "edit", resource: turnResource, property: {
                                            ...turnResource.properties.intercessor_id,
                                            isDisabled: true,
                                        }, record: {
                                            id: row.id,
                                            title: row.id,
                                            params: { intercessor_id: row.params.person_id },
                                            populated: { intercessor_id: row.populated.person_id },
                                            bulkActions: [],
                                            recordActions: [],
                                            baseError: null,
                                            errors: {},
                                        }, onChange: () => { } }))));
                        })))),
                React.createElement(DrawerFooter, null,
                    React.createElement(Box, { mt: 16 },
                        React.createElement(Button, { type: "submit", variant: "contained" },
                            React.createElement(Icon, { icon: "Save" }),
                            translate.translateAction('save', turnResource.id))))))));
};
