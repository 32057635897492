import { Button, Icon, TableRow, TableCell } from '@adminjs/design-system';
import React, { useCallback } from 'react';
import { BasePropertyComponent, useModal, useResource, useTranslation } from 'adminjs';
import { useDeleteRecord } from '../../hooks/useDeleteRecord.js';
export const PrayingQueueIntercessorItem = ({ item, onDelete }) => {
    const { translateAction } = useTranslation();
    const intercessorResource = useResource('PrayingQueueIntercessor');
    const confirmModal = useModal();
    const intercessor = item.params;
    const { executeDelete: deleteIntercessor } = useDeleteRecord(intercessorResource);
    const handleDelete = useCallback(() => {
        confirmModal.openModal({
            modalProps: {
                label: 'Confirm',
                title: 'Delete intercessor',
                subTitle: 'Are you sure you want to delete this intercessor?',
                variant: 'danger',
                icon: 'Trash2',
            },
            type: 'confirm',
            confirmAction: () => {
                deleteIntercessor(intercessor.id.toString())
                    .then(() => {
                    onDelete(intercessor.id);
                })
                    .finally(() => {
                    confirmModal.closeModal();
                });
            },
        });
    }, [intercessor.id]);
    return (React.createElement(TableRow, null,
        intercessorResource.listProperties.map((property) => (React.createElement(TableCell, { key: property.name },
            React.createElement(BasePropertyComponent, { property: property, resource: intercessorResource, record: {
                    ...item,
                    recordActions: [],
                    bulkActions: [],
                }, where: "list" })))),
        React.createElement(TableCell, { style: { textAlign: 'right' } },
            React.createElement(Button, { ml: 5, variant: "outlined", color: "danger", onClick: handleDelete },
                React.createElement(Icon, { mr: 5, icon: "Trash2" }),
                translateAction('delete', intercessorResource.id)))));
};
