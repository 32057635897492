import { Box, Loader, Table, TableCaption, Button, Icon, TableHead, TableBody, TableRow, TableCell, } from '@adminjs/design-system';
import { usePrayingQueueIntercessors } from '../../hooks/usePrayingQueueIntercessors.js';
import React, { useState } from 'react';
import { ViewHelpers, useResource, useTranslation } from 'adminjs';
import { PrayingQueueIntercessorItem } from './PrayingQueueIntercessorItem.js';
import { CreateNewPrayingQueuePerson } from './CreateNewPrayingQueuePerson.js';
const view = new ViewHelpers();
export const PrayingQueueIntercessors = ({ queueId, queueRecord }) => {
    const [showNew, setShowNew] = useState(false);
    const intercessorResource = useResource('PrayingQueueIntercessor');
    const translation = useTranslation();
    const { intercessors, loading, reload } = usePrayingQueueIntercessors(queueId);
    const translateProperty = (property) => {
        return translation.translateProperty(property, intercessorResource.id);
    };
    if (loading) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(Box, { pt: "x4" },
        React.createElement(Table, null,
            React.createElement(TableCaption, null,
                React.createElement(Box, { display: "flex", justifyContent: "flex-start", alignItems: "center" },
                    React.createElement(Button, { ml: 5, variant: "text", onClick: () => setShowNew(true) },
                        React.createElement(Icon, { icon: "PlusCircle" }),
                        translation.translateAction('new', intercessorResource.id)))),
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    intercessorResource.listProperties.map((property) => (React.createElement(TableCell, { key: property.name }, translateProperty(property.name)))),
                    React.createElement(TableCell, null))),
            React.createElement(TableBody, null, intercessors.map((row) => (React.createElement(PrayingQueueIntercessorItem, { key: row.params.id, item: row, onDelete: () => reload() }))))),
        React.createElement(CreateNewPrayingQueuePerson, { type: "intercessor", isOpen: showNew, queueId: queueId, queueRecord: queueRecord, onClose: () => setShowNew(false), onSuccess: () => {
                setShowNew(false);
                reload();
            } })));
};
