import { ApiClient, useNotice } from "adminjs";
import { useCallback, useState } from "react";
const api = new ApiClient();
export const useDeleteRecord = (resource) => {
    const showNotice = useNotice();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const executeDelete = useCallback(async (recordId) => {
        try {
            setLoading(true);
            const res = await api.recordAction({ actionName: "delete", resourceId: resource.id, recordId, method: "post" });
            if (res.data.notice) {
                showNotice(res.data.notice);
            }
        }
        catch (error) {
            setError(error.message);
            showNotice({ message: error.message, type: "error" });
        }
        finally {
            setLoading(false);
        }
    }, []);
    return { loading, error, executeDelete };
};
