import React from 'react';
import { Box, H5, Button, Text, MessageBox } from '@adminjs/design-system';
import { useTranslation } from 'adminjs';
import { useSelector } from 'react-redux';
const Login = ({ action, errorMessage }) => {
    const { translateComponent, translateMessage } = useTranslation();
    const branding = useSelector((state) => state.branding);
    return (React.createElement(Box, { flex: true, style: { alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100%' }, variant: "grey" },
        React.createElement(Box, { bg: "dark", height: "auto", flex: true, boxShadow: "login", width: [1, 2 / 3, 'auto'] },
            React.createElement(Box, { as: "form", action: action, method: "POST", p: "x3", flexGrow: 1, width: ['100%', '100%', '480px'] },
                React.createElement(H5, { marginBottom: "xxl" }, branding.logo ? (React.createElement("img", { style: { width: 150, margin: '0 auto', display: 'block' }, src: branding.logo, alt: branding.companyName })) : (branding.companyName)),
                !!errorMessage && (React.createElement(MessageBox, { my: "lg", message: errorMessage.split(' ').length > 1 ? errorMessage : translateMessage(errorMessage), variant: "danger" })),
                React.createElement(Text, { mt: "xl", textAlign: "center" },
                    React.createElement(Button, { variant: "contained", type: "button", onClick: () => {
                            window.location.href = '/auth/login/google';
                        } }, translateComponent('Login.loginButton')))))));
};
export default Login;
