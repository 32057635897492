import React from 'react';
import { ValueGroup } from '@adminjs/design-system';
import { useTranslation } from 'adminjs';
import { get as _get } from 'lodash';
import dayjs from '../../helpers/dayjs.js';
const NotificationPreference = ({ record, property, where }) => {
    const { translateProperty } = useTranslation();
    const showLabel = !property.hideLabel && where !== 'list';
    const value = _get(record.params, property.path);
    if (!value) {
        return null;
    }
    const time = dayjs().tz('Europe/Warsaw').startOf('day').add(dayjs.duration(value)).format('HH:mm');
    if (where === 'list') {
        return time;
    }
    return (React.createElement(ValueGroup, { label: showLabel ? translateProperty(property.name, property.resourceId) : null }, time));
};
export default NotificationPreference;
