import { Button, Icon, TableRow, TableCell } from '@adminjs/design-system';
import React, { useCallback } from 'react';
import { BasePropertyComponent, useModal, useResource, useTranslation } from 'adminjs';
import { useDeleteRecord } from '../../hooks/useDeleteRecord.js';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
export const PrayingQueuePrayeeItem = ({ item, onDelete }) => {
    const { translateAction } = useTranslation();
    const prayeeResource = useResource('PrayingQueuePrayee');
    const confirmModal = useModal();
    const intercessor = item.params;
    const { executeDelete: deleteIntercessor } = useDeleteRecord(prayeeResource);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });
    const handleDelete = useCallback(() => {
        confirmModal.openModal({
            modalProps: {
                label: 'Confirm',
                title: 'Delete intercessor',
                subTitle: 'Are you sure you want to delete this intercessor?',
                variant: 'danger',
                icon: 'Trash2',
            },
            type: 'confirm',
            confirmAction: () => {
                deleteIntercessor(intercessor.id.toString())
                    .then(() => {
                    onDelete(intercessor.id);
                })
                    .finally(() => {
                    confirmModal.closeModal();
                });
            },
        });
    }, [intercessor.id]);
    return (React.createElement(TableRow, { ref: setNodeRef, ...attributes, style: {
            transform: CSS.Transform.toString(transform),
            transition,
            backgroundColor: '#151419',
        } },
        prayeeResource.listProperties.map((property) => (React.createElement(TableCell, { key: property.name },
            React.createElement(BasePropertyComponent, { property: property, resource: prayeeResource, record: {
                    ...item,
                    recordActions: [],
                    bulkActions: [],
                }, where: "list" })))),
        React.createElement(TableCell, { style: { textAlign: 'right' } },
            React.createElement(Button, { ml: 5, variant: "outlined", color: "danger", onClick: handleDelete },
                React.createElement(Icon, { mr: 5, icon: "Trash2" }),
                translateAction('delete', prayeeResource.id)),
            React.createElement(Button, { ...listeners, ml: 5, variant: "text", size: "icon", color: "info" },
                React.createElement(Icon, { icon: "Menu" })))));
};
