AdminJS.UserComponents = {}
import Dashboard from '../frontend/components/Dashboard/index'
AdminJS.UserComponents.Dashboard = Dashboard
import Login from '../frontend/components/Login/index'
AdminJS.UserComponents.Login = Login
import UserAvatar from '../frontend/components/UserAvatar/index'
AdminJS.UserComponents.UserAvatar = UserAvatar
import PrayingQueuePeople from '../frontend/components/PrayingQueuePeople/index'
AdminJS.UserComponents.PrayingQueuePeople = PrayingQueuePeople
import PrayingQueueTurns from '../frontend/components/PrayingQueueTurns/index'
AdminJS.UserComponents.PrayingQueueTurns = PrayingQueueTurns
import NotificationPreference from '../frontend/components/NotificationPreference/index'
AdminJS.UserComponents.NotificationPreference = NotificationPreference